import React, { ReactElement, useState } from 'react';
import { CaretDownOutlined, CheckOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import Button from 'antd/es/button';
import Dropdown from 'antd/es/dropdown';
import { MenuProps } from 'antd/es/menu';
import moment from 'moment';
import { MomentDate } from '../../../models/date.model';
import { Filter, MenuItem, getItem, getMonthFilterOptions } from '../../../shared/helper';
import { useSalesforceTrigger } from '../../../shared/useAnalytics';

interface MetricsSectionViewContainerProps {
  children: ReactElement;
  onMonthChange: (month: MomentDate) => void;
  kpisLoading: boolean;
}

const EmptySpace = () => <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>;

export const MetricsSectionViewContainer = ({
  children,
  onMonthChange,
  kpisLoading,
}: MetricsSectionViewContainerProps) => {
  const monthFilterOptions: Filter = getMonthFilterOptions();
  const { triggerClick } = useSalesforceTrigger('VendorDashboard');

  const [dropdownOption, setDropdownOption] = useState(Object.keys(monthFilterOptions)[0]);

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    setDropdownOption(e.key);
    triggerClick({ component: 'filter', type: 'date' });
    onMonthChange(moment(monthFilterOptions[e.key].start));
  };
  const items: MenuItem[] = [
    ...Object.keys(monthFilterOptions).map((key) =>
      getItem(key, key, key === dropdownOption ? <CheckOutlined /> : <EmptySpace />),
    ),
  ];
  if (kpisLoading) {
    return (
      <div className="loading">
        <Spin tip="Loading..." />
      </div>
    );
  }
  return (
    <div className="vendor-dashboard-layout">
      <div className="vendor-dashboard-layout-title">
        <Dropdown trigger={['click']} disabled={false} menu={{ items, onClick: handleMenuClick }}>
          <Button>
            {dropdownOption} <CaretDownOutlined />
          </Button>
        </Dropdown>
      </div>
      <div className="vendor-dashboard-layout-container">{children}</div>
    </div>
  );
};
