type Page = 'PortfolioOverview' | 'VendorDashboard';
type Action = 'click' | 'hover' | 'navigation';
export type ActionData = { [key: string]: unknown };

export type SalesforceEvent = {
  operation: Page;
  status: 'success' | 'failure';
  action: Action;
  actionData?: ActionData;
};

export const triggerSalesforceEvent = (page: Page, action: Action, actionData?: ActionData) => {
  const event: SalesforceEvent = {
    status: 'success',
    action,
    operation: page,
    actionData,
  };
  /* eslint-disable no-restricted-globals */
  parent.postMessage(event, '*');
};

export const useSalesforceTrigger = (page: Page) => {
  const triggerClick = (actionData: ActionData) => {
    triggerSalesforceEvent(page, 'click', actionData);
  };
  return { triggerClick };
};
