import React, { useEffect, useState } from 'react';
import { VisibilityState } from '@tanstack/react-table';
import { Spin, Typography } from 'antd';
import { useSearchParams } from 'react-router-dom';
import './portfolio-overview.scss';
import { ChartContainer } from '../../components/charts-container/charts-container';
import { PortfolioPerformance } from '../../components/portfolio-performance/portfolio-performance';
import { PortfolioPerformanceTable } from '../../components/portfolio-performance-table/portfolio-performance-table';
import { AMC_VIEWS } from '../../models/constants';
import { MomentDate } from '../../models/date.model';
import { getIsInvalid, getIsLoaded, setSessionToken } from '../../services/auth/auth-slice';
import { Filter, getquarterFilterOptions } from '../../shared/helper';
import { useAppDispatch, useAppSelector } from '../../shared/hooks';
import { useSalesforceTrigger, ActionData } from '../../shared/useAnalytics';
import { tabs } from './column-def';
import { useAccountManagerHierarchy } from './useAccountManagerHierarchy';
import { useFilterData } from './useFilterData';
import { useKpiByMonth } from './useKpiByMonth';
import { useKpiConfig } from './useKpiConfig';
import { useTableHook } from './useTableHook';

const { Title } = Typography;

export const PortfolioOverview = () => {
  const dateFilterOptions: Filter = getquarterFilterOptions();
  const [searchParams] = useSearchParams();
  const sfToken: string = searchParams.get('sessionId') || '';
  const view = searchParams.get('view') || AMC_VIEWS.ACOOUNT_MANAGER;
  const [accountOwnerIds, setAccountOwnerIds] = useState<string[]>([]);
  const [filters, setFilters] = useState<{
    verticals: string[];
    brands: string[];
    groups: string[];
    cities: string[];
  }>({
    verticals: [],
    brands: [],
    groups: [],
    cities: [],
  });
  const isInvalidSession = useAppSelector(getIsInvalid);
  const isSessionLoaded = useAppSelector(getIsLoaded);
  const dispatch = useAppDispatch();
  const [dateRange, setDateRange] = useState<{
    to: MomentDate;
    from: MomentDate;
    precision: 'month' | 'quarter' | 'day';
  }>({
    to: dateFilterOptions.QTD.end,
    from: dateFilterOptions.QTD.start || '',
    precision: 'quarter',
  });
  const [tab, setTab] = useState<'commercial' | 'operational'>('commercial');
  const [searchText, setSearchText] = useState('');
  const { amHierarchyLoading } = useAccountManagerHierarchy();

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (view === AMC_VIEWS.ACOOUNT_MANAGER) {
      setAccountOwnerIds([searchParams.get('account_owner_id') || '']);
    }
    dispatch(setSessionToken(sfToken));
  }, []);

  const {
    isLoading,
    isRefetching,
    error,
    table,
    tableContainerRef,
    fetchMoreOnBottomReached,
    isFetchingNextPage,
    totalDBRowCount,
    setColumnVisibility,
  } = useTableHook(dateRange, searchText, accountOwnerIds, filters);
  const {
    chartData = [],
    chartDataLoading,
    chartDataError,
    prevChartData = [],
    labels = [],
  } = useKpiByMonth(accountOwnerIds, dateRange, filters, searchText);
  const { kpiConfigLoading, kpiConfigError, kpiConfig = [] } = useKpiConfig();

  const { filterData } = useFilterData(accountOwnerIds);

  const { triggerClick } = useSalesforceTrigger('PortfolioOverview');
  /* eslint-disable react-hooks/exhaustive-deps, @typescript-eslint/no-floating-promises */
  const onRangeChange = (
    from: MomentDate,
    to: MomentDate,
    precision: 'month' | 'quarter' | 'day',
  ) => {
    const dateRangeSelection = {
      to,
      from,
      precision,
    };
    const actionData: ActionData = {
      duration: {
        value: to?.diff(from, 'days').toString() || '',
        unit: 'days',
      },
      actionName: 'filter',
    };
    triggerClick(actionData);
    setDateRange(dateRangeSelection);
  };
  const onTargetedAMsChange = (targetedAccountOwnerIds: string[]) => {
    setAccountOwnerIds(targetedAccountOwnerIds);
  };
  const onFiltersChange = (filter: {
    verticals: string[];
    brands: string[];
    groups: string[];
    cities: string[];
  }) => {
    setFilters(filter);
  };
  const onTabChange = (tabValue: 'commercial' | 'operational') => {
    setTab(tabValue);
  };
  //  useEffect to apply commercial tab to initial render
  useEffect(() => {
    if (kpiConfig?.length) {
      const initialColumnVisibility: VisibilityState = table
        .getAllColumns()
        .reduce((visibilityState, column) => {
          visibilityState[column.id] = tabs[tab]
            .filter((t) => kpiConfig.some((kpi) => kpi.kpiName === t))
            .concat(tabs.common)
            .includes(column.id);
          return visibilityState;
        }, {} as VisibilityState);
      setColumnVisibility(initialColumnVisibility);
    }
  }, [tab, kpiConfig]);

  if (!isSessionLoaded || amHierarchyLoading) {
    return (
      <div className="loading-container">
        <Spin tip="Loading..." />
      </div>
    );
  }

  if (isInvalidSession) {
    return (
      <div className="warning-message-container">
        <Title level={5}>Invalid token</Title>
      </div>
    );
  }

  return (
    <div className="portfolio-overview-organism">
      <PortfolioPerformance
        onTargetedAMsChange={onTargetedAMsChange}
        onFiltersChange={onFiltersChange}
        isFilterDisabled={isLoading || isRefetching}
        onRangeChange={onRangeChange}
        onTabChange={onTabChange}
        onSearch={setSearchText}
        view={view}
        filterData={filterData}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <ChartContainer
            tab={tab}
            chartData={chartData}
            kpiConfig={kpiConfig}
            prevChartData={prevChartData}
            loading={chartDataLoading || kpiConfigLoading}
            error={chartDataError && kpiConfigError}
            labels={labels}
          />
          <PortfolioPerformanceTable
            error={!!error}
            loading={isLoading || isRefetching}
            table={table}
            tableContainerRef={tableContainerRef}
            fetchMoreOnBottomReached={fetchMoreOnBottomReached}
            isFetchingNextPage={isFetchingNextPage}
            totalDBRowCount={totalDBRowCount}
          />
        </div>
      </PortfolioPerformance>
    </div>
  );
};
