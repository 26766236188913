import React from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';
import {
  getCoreRowModel,
  SortingState,
  useReactTable,
  VisibilityState,
} from '@tanstack/react-table';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { MomentDate } from '../../models/date.model';
import { PortfolioOverviewAxiosResponse } from '../../models/kpi.model';
import { getIsInvalid, getToken } from '../../services/auth/auth-slice';
import { useAppSelector } from '../../shared/hooks';
import { portfolioPerformanceCols } from './column-def';
import { useAccountManagerHierarchy } from './useAccountManagerHierarchy';

const DATE_FORMAT = 'YYYY-MM-DD';

// todo: geId and role should not be undefinable here but available, this is because accountManagerHierarchy is nullable but should not be
export const useTableHook = (
  dateRange: { to: MomentDate; from: MomentDate },
  searchText: string,
  accountOwnerIds: string[],
  filters: {
    verticals: string[];
    brands: string[];
    groups: string[];
    cities: string[];
  },
) => {
  const vdToken = useSelector(getToken);
  const isInvalidSession = useAppSelector(getIsInvalid);
  const tableContainerRef = React.useRef<HTMLDivElement>(null);
  const [columnVisibility, setColumnVisibility] = React.useState<VisibilityState>({});
  const [sorting, setSorting] = React.useState<SortingState>([
    {
      id: 'sf_grid_id',
      desc: false,
    },
  ]);
  const { accountManagerHierarchy } = useAccountManagerHierarchy();

  const fetchData = async (page = 0, limit = 50, sortColumn = 'sf_grid_id', sortDir = 'asc') => {
    return axios.request({
      method: 'get',
      url: `/vendor-dashboard-service/portfolio-overview?from=${dateRange?.from?.format(
        DATE_FORMAT,
      )}&to=${dateRange.to?.format(
        DATE_FORMAT,
      )}&user_ids=${accountOwnerIds}&sortBy=${sortColumn}&sortDir=${sortDir}&pageSize=${limit}&page=${page}&search=${searchText}&ge_id=${
        accountManagerHierarchy?.geId
      }&role=${accountManagerHierarchy?.role}&groups=${filters.groups}&brands=${
        filters.brands
      }&cities=${filters.cities}&verticals=${filters.verticals}`,
      headers: {
        'x-vendorDashboard-token': vdToken,
      },
    });
  };

  const fetchSize = 50;

  const { data, error, fetchNextPage, isFetching, isRefetching, isFetchingNextPage, isLoading } =
    useInfiniteQuery<PortfolioOverviewAxiosResponse>(
      ['table-data', sorting, searchText, dateRange, accountOwnerIds, filters], // adding sorting state as key causes table to reset and fetch from new beginning upon sort
      async ({ pageParam = 1 }) => {
        const sortDir = sorting[0]?.desc ? 'desc' : 'asc';
        return fetchData(pageParam, fetchSize, sorting[0]?.id, sortDir);
      },
      {
        getNextPageParam: (_lastGroup, groups) => groups.length,
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        enabled:
          !!vdToken &&
          !isInvalidSession &&
          !!accountOwnerIds.length &&
          !!accountManagerHierarchy?.geId &&
          !!accountManagerHierarchy?.role,
      },
    );

  const flatData = React.useMemo(
    () => data?.pages?.flatMap((page) => page.data.data) ?? [],
    [data],
  );
  const totalRowData = React.useMemo(() => data?.pages[0].data.total, [data]);
  const totalDBRowCount =
    flatData.length === 0 ? 0 : parseInt(data?.pages[0]?.data?.data[0]?.fullCount || '0', 10);
  const totalFetched = flatData.length;
  // called on scroll and possibly on mount to fetch more data as the user scrolls and reaches bottom of table
  const fetchMoreOnBottomReached = React.useCallback(
    (containerRefElement?: HTMLDivElement | null) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
        // once the user has scrolled within 300px of the bottom of the table, fetch more data if there is any
        if (
          scrollHeight - scrollTop - clientHeight < 300 &&
          !isFetching &&
          totalFetched < totalDBRowCount
        ) {
          /* eslint-disable @typescript-eslint/no-floating-promises */
          fetchNextPage();
        }
      }
    },
    [fetchNextPage, isFetching, totalFetched, totalDBRowCount],
  );
  const table = useReactTable({
    data: flatData,
    columns: portfolioPerformanceCols,
    enableColumnResizing: true,
    columnResizeMode: 'onChange',
    getCoreRowModel: getCoreRowModel(),
    manualSorting: true,
    state: {
      sorting,
      columnVisibility,
    },
    onSortingChange: setSorting,
    debugTable: true,
    onColumnVisibilityChange: setColumnVisibility,
  });
  // a check on mount and after a fetch to see if the table is already scrolled to the bottom and immediately needs to fetch more data
  React.useEffect(() => {
    fetchMoreOnBottomReached(tableContainerRef.current);
  }, [fetchMoreOnBottomReached]);
  return {
    isLoading,
    isRefetching,
    error,
    data,
    table,
    totalRowData,
    tableContainerRef,
    fetchMoreOnBottomReached,
    isFetchingNextPage,
    totalDBRowCount,
    setColumnVisibility,
  };
};
